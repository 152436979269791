import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';
import Routes from './routes';
import GlobalStyles from './styles/global';

TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID || '', dataLayerName: 'HomeDataLayer' });

function App() {
	return (
		<HelmetProvider>
			<ThemeProvider theme={theme}>
				<Router>
					<Routes />
				</Router>
				<GlobalStyles />
				<Toaster toastOptions={{ duration: 5000 }} />
			</ThemeProvider>
		</HelmetProvider>
	);
}

export default App;
