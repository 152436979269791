const theme = {
	colors: {
		primaryOrange: '#F18E4A',
		primaryOrangeDark: '#EA7804',
		primaryBlue: '#4DB3CF',
		primaryBlueDark: '#015CAB',
		primaryDarkest: '#00396C',
		primaryDark: '#0062AA',

		primaryOrangeLogo: '#e3671e',
		primaryBlueLogo: '#065DAC',

		textOrangeDark: '#EA7804',
		textBlue: '#4DB3CF',
		textBlueDark: '#015CAB',
		textAttention: '#FFC500',
		textDarkestSubtitle: '#333333',
		textDarkestMedium: '#444444',
		textWhite: '#FFFFFF',

		bgGrey: '#EFEEEE',

		neutralMediumThree: '#666666',
		neutralMediumFour: '#999999',
		neutralMediumFive: '#CCCCCC',
		neutralMediumSix: '#ECECEC',
		neutralMediumNine: '#707070',
		neutralWhite: '#FFFFFF',

		shadowDarkerRegular: '#5555554D',
	},
	fonts: {
		sourceSansPro: 'Source Sans Pro, sans-serif',
	},
	fontSizes: {
		'48': '3rem',
		'34': '2.125rem',
		'30': '1.875rem',
		'28': '1.75rem',
		'26': '1.625rem',
		'24': '1.5rem',
		'22': '1.375rem',
		'20': '1.25rem',
		'18': '1.125rem',
		'16': '1rem',
		'14': '0.875rem',
		'12': '0.75rem',
		'8': '0.5rem',
	},
	fontWeights: {
		bolder: '900',
		bold: '700',
		regular: '400',
		light: '300',
		extraLight: '200',
	},
	shadows: {
		'1': '0px 0px 8px #99999929',
		'2': '0px 0px 8px #5555554D',
		'3': '0px 6px 12px #000000B2',
	},
	space: {
		'90': '5.625rem',
		'82': '5.125rem',
		'64': '4rem',
		'56': '3.5rem',
		'48': '3rem',
		'40': '2.5rem',
		'32': '2rem',
		'24': '1.5rem',
		'16': '1rem',
		'8': '0.5rem',
		'4': '0.25rem',
	},
	borderWidths: {
		'1': '1px',
		'2': '2px',
		'3': '3px',
		'8': '8px',
	},
	lineHeights: {
		tightest: '1',
		tight: '1.30',
		medium: '1.50',
		distant: '1.70',
	},
	media: {
		xs: '360px',
		sm: '576px',
		md: '768px',
		lg: '1120px',
		xl: '1248px',
		xxl: '1440px',
	},
};

export default theme;
