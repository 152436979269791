import { PathParams } from './interfaces';

import * as P from './pages';

const pages: PathParams[] = [
	{
		page: P.Home,
		title: 'Bula Digital',
		subtitle: 'Medicamento não encontrado',
		pathname: '/',
	},
	{
		page: P.Home,
		title: 'Bula Digital',
		pathname: '/:id',
	},
	{
		page: P.PdfViewer,
		title: 'Bula Digital - Visualizar PDF',
		pathname: '/visualizar-pdf/:name',
	},
];

export default pages;
