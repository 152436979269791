import { LoadingProps } from './interfaces';
import { AnimatedLoading } from './styles';

export default function Loading({ color, size }: LoadingProps) {
	return (
		<AnimatedLoading className="loading-icon" size={size} color={color}>
			<div />
			<div />
			<div />
			<div />
		</AnimatedLoading>
	);
}
