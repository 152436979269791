import { css, DefaultTheme, ThemeProps } from 'styled-components';
import {
	GetterResponse,
	ThemeColorsTypes,
	ThemeFontsTypes,
	ThemeFontSizesTypes,
	ThemeMediaBPTypes,
	ThemeFontWeightsTypes,
	ThemeLineHeightsTypes,
	ThemeMediaSpaceTypes,
	ThemeBorderTypes,
	ResponsiveTypes,
} from './interfaces';

export const getThemeFont = (font?: ThemeFontsTypes): GetterResponse => {
	return (props) => props.theme.fonts[font || 'sourceSansPro'];
};

export const getThemeColor = (color?: ThemeColorsTypes): GetterResponse => {
	return (props) => props.theme.colors[color || 'textDarkestSubtitle'];
};

export const getThemeFontSize = (size?: ThemeFontSizesTypes): GetterResponse => {
	return (props) => props.theme.fontSizes[size || '16'];
};

export const getThemeFontWeight = (weight?: ThemeFontWeightsTypes): GetterResponse => {
	return (props) => props.theme.fontWeights[weight || 'regular'];
};

export const getThemeLineHeight = (lineHeight?: ThemeLineHeightsTypes): GetterResponse => {
	return (props) => props.theme.lineHeights[lineHeight || 'tight'];
};

export const getThemeMedia = (bp?: ThemeMediaBPTypes): GetterResponse => {
	return (props) => props.theme.media[bp || 'xs'];
};

export const getThemeSpace = (space?: ThemeMediaSpaceTypes): GetterResponse => {
	return (props) => props.theme.space[space || '8'];
};

export const getThemeBorder = (size: ThemeBorderTypes, color: ThemeColorsTypes) => {
	return (props: ThemeProps<DefaultTheme>) => `
		${props.theme.borderWidths[size || '1']} solid ${props.theme.colors[color || 'textDarkestSubtitle']}
	`;
};

export const getPropertyByMedia = (
	property: string,
	value?: ResponsiveTypes<string | number> | string | number,
	initialValue?: string,
) => {
	const varName = `--prop-${property}`;
	const generateVar = (val?: string | number) => (val ? `${varName}: ${val};` : '');
	const generateProperty = (val: string | number) => `${property}: ${val};`;

	if (typeof value === 'object') {
		return css`
			${generateVar(value.xs || initialValue)};

			@media screen {
				@media (min-width: ${getThemeMedia('sm')}) {
					${generateVar(value.sm)};
				}
				@media (min-width: ${getThemeMedia('md')}) {
					${generateVar(value.md)};
				}
				@media (min-width: ${getThemeMedia('lg')}) {
					${generateVar(value.lg)};
				}
				@media (min-width: ${getThemeMedia('xl')}) {
					${generateVar(value.xl)};
				}
				@media (min-width: ${getThemeMedia('xxl')}) {
					${generateVar(value.xxl)};
				}
			}

			${generateProperty(`var(${varName})`)}
		`;
	}

	if (value) {
		return css`
			${generateProperty(value)}
		`;
	}

	return css``;
};

export const getPxToRemValue = (value: number) => {
	const pxEquivalentToRem = 0.0625;
	return `${value * pxEquivalentToRem}rem`;
};
