import styled from 'styled-components';
import { getThemeColor, getThemeSpace } from 'styles/helpers';
import { LoadingProps } from './interfaces';

export const AnimatedLoading = styled.div<LoadingProps>`
	display: flex;
	align-items: center;
	justify-content: center;

	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: ${({ size }) => `${size || '24'}px`};
		height: ${({ size }) => `${size || '24'}px`};
		margin: ${getThemeSpace('8')};
		border: 3px solid;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: ${({ color }) => getThemeColor(color || 'primaryDarkest')} transparent transparent transparent;
	}

	div:nth-child(1) {
		animation-delay: -0.45s;
	}

	div:nth-child(2) {
		animation-delay: -0.3s;
	}

	div:nth-child(3) {
		animation-delay: -0.15s;
	}

	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
